.admin-container {
    display: flex;
    align-content: center;
    justify-content: center;
}

.admin-box-static {
    margin: 2em 0em;
    padding: 1em;

    width: 75em;

    border: 0.2em;
    border-radius: 1em;
    border-color: #282c34;
    border-style: solid;
}

.menu {
    display: flex;
    justify-content: flex-start;
}

.menu-block {
    width: 9.5em;
    height: 2em;
    
    border: 0.2em;
    border-radius: 1em;
    border-color: black;
    border-style: solid;

    margin-right: 0.5em;

    display: flex;
    align-content: center;
    justify-content: center;
}

.menu-block-selected {
    background-color: #282c34;
    color: white;
}

.menu-block-content {
    align-self: center;
}

#table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
#table td, #table th {
    border: 1px solid #ddd;
    padding: 8px;
}

#table tr:nth-child(even){background-color: #f2f2f2;}

#table tr:hover {background-color: #ddd;}

#table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #282c34;
    color: white;
}