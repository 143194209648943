* {
	box-sizing: border-box;
}

.content-container {
	display: flex;
	align-content: center;
	justify-content: center;
}

.content-box-static {
	margin: 2em 0em;
	padding: 1em;

	width: 75em;

	border: 0.2em;
	border-radius: 1em;
	border-color: #282c34;
	border-style: solid;
}

.contact {
	width: 44%;
}

.full-length-contact {
	width: 94%;
}

.contact-form{
	display: inline;
}

#bericht {
	min-height: calc(1em * 15);
	font-family: Arial, Helvetica, sans-serif;
	resize: none;
}

.g-recaptcha {
	margin: 0.5em;
}

.edit-event-box {
	position: relative;
	width: fit-content;
	min-width: 80%;
	max-width: 80%;
	height: 300px;
	align-self: center;
	justify-self: stretch;

	display: table-cell;
	vertical-align: middle;

	overflow: hidden;

	margin: 1em 0em;

	border: 0.1em;

	border-color: #282c34;
	border-style: solid;

	-webkit-box-shadow: 2px 2px 5px #333333;
}

.event-edit-container {
	height: 40px;
	padding: 0.5em;
	margin-bottom: 10px;
	display: block;
	border: 0.05em;
	border-color: #282c34;
	border-style: solid;
	-webkit-box-shadow: 2px 2px 5px #333333;
}

.event-edit-button {
	float: left;
	display: inline;
	align-items: center;
	justify-content: center;
	margin: 0 0.5em;
	padding: 0 0.7em;
	border: 0.2em;
	border-style: solid;
}

.delete {
	background: #8D101A;
	float: right;
}

.event-edit-button:hover {
	background: #333333;
}

.delete:hover {
	background: #EC5D67;
}

@media screen and (max-width:800px) {
	.contact {
		width: 94%;
	}
}