* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.home-center {
    width: 100%;
    height: 65vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    z-index: 40;
    background-image: url("https://assets.csarotterdam.nl/background/219.png");

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.home-logo-image {
    max-width: 50vw;
    position: absolute;
    z-index: 41;
}

.home-center img {
    width: 100%;
    align-self: center;
}

.image-separator {
    position: absolute;
    width: 100%;
    max-width: 100%;
    bottom: -2px;
    overflow: hidden;
    z-index: 45;
}

.image-separator svg {
    fill: white;
}

.image-separator-bottom {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
}

.events{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

}


.home-event {
    align-self: center;
    justify-content: center;
    align-content: center;

}

.event-list-container{
    display: list-item;
}

.event-list-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.home-event-box {
    position: relative;
    width: 80vw;
    height: 250px;
    align-self: center;
    justify-self: stretch;
    float: left;
    display: table-cell;
    vertical-align: middle;

    overflow: hidden;

    margin: 1em 0em;

    border: 0.1em;

    border-color: #282c34;
    border-style: solid;

    -webkit-box-shadow: 2px 2px 5px #333333;
}


.home-event-box h1,h2,h3,h4,h5,h6,p {
    padding: 0.5em;
}

.home-event-image-container {
    display: inline-block;
    text-align: center;
    overflow: hidden;
    width: 30%;
    height: 100%;
    max-height: 250px;
    float: left;

}

.home-event-image-container img {
    max-width: 100%;
    position: relative;
    transition: all 0.3s;
    display: inline-block;
    height: 100%;
}

.home-event-image-container img:hover {
    transform: scale(1.1); /* (110% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.home-event-data-container {
    display: inline-block;
    width: 70%;
    height: 80%;
    float: right;

}

.btn-more{
    height: 15%;
    display: block;
    float: right;
    background-color: #006a6b;
    border: 1px solid #006a6b;
    color: white;
    padding-left: 15px;
    padding-right: 15px;

    margin-right: 15px;
    position: relative;
    overflow: hidden;
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s;
}

.btn-more div i {
    font-size: 30px;
    float: right;
}


.btn-more:after {
    position: absolute;
    content: ' ';
    bottom: 0;
    left: 20px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    z-index: -1;
}

.btn-more div:first-child {
    opacity: 1;
    padding-top: 3px;
}

.btn-more div:last-child {
    opacity: 0;
}

.btn-more:hover div:last-child{
    opacity: 1;
}

.btn-more:hover div:first-child{
    opacity: 0;
    height: 0;
}

.btn-more:hover {
    background-color: #2E8585;
    border-color: #2E8585;
    box-shadow: none;
    outline: 0;
}

.btn-more:hover:after {
    transform: scale(20);
    -moz-transform: scale(20);
    -webkit-transform: scale(20);
    -o-transform: scale(20);
    background-color: #F73F52;
}


hr {
    width: 80%;
    margin-left: 10%;
    border-top: 1px solid;
    border-color: #333333;
}

.home-event-overview-box{
    display: inline;
    text-align: left;
    margin: 2em 0em;
    padding: 1em;
    position: relative;
    width: 80vw;

    border: 0.05em;
    border-color: #282c34;
    border-style: solid;

    -webkit-box-shadow: 2px 2px 5px #333333;
}

.home-event-overview-details > img {
    float: left;
    display: inline-block;
    max-width: 400px;
    padding: 0.5em;

}

.home-event-overview-info {
    padding: 0.5em;
    margin: 0.5em;
    display: block;
    border: 0.05em;
    border-color: #282c34;
    border-style: solid;
    -webkit-box-shadow: 2px 2px 5px #333333;
}

.home-event-overview-details {
    width: 100%;
}

.home-event-overview-text {
    width: 100%;
}

.home-event-icon {
    height: 2vh;
    padding: 0.01em;
    padding-right: 0.5em;
    color: black;
}
@media(max-width: 820px){
    .home-logo-image {
        max-width: 50vw;
        position: absolute;
        z-index: 41;
    }

    .home-event-box{
        height: auto
    }

    .home-event-image-container {
        display: flex;
        position: relative;
        text-align: center;
        overflow: hidden;
        width: 100%;
        height: auto;
        margin-right: 0;
        float: right;
    }

    .home-event-image-container img {
        max-width: 100%;
        transition: all 0.3s;
        display: block;
        width: 100%;
        height: auto;
        transform: scale(1);
    }

    .home-event-image-container:hover img{
        transform: scale(1.1); /* (110% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }

    .home-event-overview-details > img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

