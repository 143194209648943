.footer-spacer {
    width: 100%;
    height: 2em;
}

.footer-container {
    background-color: #282c34;
    position: absolute;
    height: 5em;
    width: 100%;
    margin-top: 1em;
    bottom: 3.2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0.2em 5px #484c54;
}

.footer-under-container{
    position: absolute;
    height: 3em;
    text-align: end;
    width: 100%;
    bottom:0;

}

.footer-column i {
   font-size: 30px;
    color: white;
}

.footer-left-container{
    float: left;
    margin-left: 1em;
    height: 100%;
    max-width: 50%;

    display: flex;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-start;

}

.footer-right-container{
    margin-right: 1em;
    height: 100%;
    max-width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.footer-column{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

}

.footer-column-image{
    height: 3vh;
    width: auto;
    margin-right: .5vw;
    margin-left: .5vw;
    filter: invert();
}

.footer-column-separator {
    width: 0;
    height: 2.5vw;
    color: white;
    align-self: center;
    margin-left: 0.5em;
    margin-right: 0.5em;
}


.footer-text {
    font-size: 1em;
    color: white;
    align-self: center;
    padding: 0.5em
}


.footer-text-short {
    display:none;
    color: black;
    align-self: flex-start;
    padding: 0.2em
}

.under{
    font-size: 0.8em;
    color: #282c34;
    align-self: flex-start;
    padding: 0.2em;
}

.link:hover{
    color: lightblue;

}

.footer-linkbox{
    float: left;
    height: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer-under-container i {
    color: black;
}

@media(max-width: 820px){
    .footer-text-short {display: inline-block}
    .footer-text-right-short {display: inline-block}
    .footer-text{display: none}
    .footer-text-right{display: none}
    .footer-column p {display: none;}
}
